import React from "react"
import { PageProps } from "gatsby"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { get } from "lodash"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import classnames from "classnames"
import { navigate } from "gatsby"

import * as R from "@constants/routes"
import Layout from "@components/Layout"
import SEO from "@components/SEO"
import { useTranslation } from "@hooks"

import Container from "react-bulma-components/lib/components/container"
import Section from "react-bulma-components/lib/components/section"
import Content from "react-bulma-components/lib/components/content"
import Heading from "react-bulma-components/lib/components/heading"
import Hero from "react-bulma-components/lib/components/hero"

import styles from "./styles.module.scss"

const ArticleTemplate = (props: PageProps) => {
  const article = get(props, "data.contentfulBlogPost")

  if (!article) {
    if (typeof window !== "undefined") {
      return navigate(R.NOT_FOUND)
    }
    return null
  }

  const [t] = useTranslation()

  return (
    <Layout className={styles.Article}>
      <SEO
        title={`${article.title}`}
        description={article.description?.childMarkdownRemark.html.replace(
          /<[^>]+>/g,
          ""
        )}
      />
      <Helmet title={`${article.title}`} />
      <Hero size="large">
        <BackgroundImage Tag="section" fluid={article.heroImage?.fluid}>
          <Hero.Body>
            <Container className={classnames("has-text-centered")}>
              <Heading
                className={classnames(
                  styles.Article__heading,
                  "has-text-white-bis"
                )}
              >
                {article.title}
              </Heading>
              {article.author && (
                <>
                  <Img
                    fluid={article.author.image?.fluid}
                    alt={`${article.author.name} image`}
                    className={styles.Article__authorImage}
                  />
                  <Heading
                    className={classnames(
                      styles.Article__heading,
                      "has-text-white-bis"
                    )}
                    size={5}
                    renderAs={"p"}
                    marginless
                  >
                    {t("templates.Article.written")} {article.author.name}
                  </Heading>
                </>
              )}
              <p
                className={classnames(
                  styles.Article__date,
                  "has-text-white-bis"
                )}
              >
                {article.publishDate}
              </p>
            </Container>
          </Hero.Body>
        </BackgroundImage>
      </Hero>
      <Container>
        <Section>
          <Content className={styles.Article__content}>
            <div
              dangerouslySetInnerHTML={{
                __html: article.body?.childMarkdownRemark.html,
              }}
            />
          </Content>
        </Section>
      </Container>
    </Layout>
  )
}

export default ArticleTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(maxWidth: 1180, quality: 100, background: "rgb:000000") {
          ...GatsbyContentfulFluid
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      author {
        name
        image {
          fluid(maxWidth: 128, background: "rgb:000000") {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
